import { History } from 'history';
import { Buffer } from 'buffer';
import loadImage from 'blueimp-load-image';
import React, { Key, ReactText } from 'react';
import _ from 'lodash';
import { detect } from './browser-detection';
import { log, LogLevel } from './loggerWrapper';
import theme from '../theme';
import { DataService, FetchDocumentPayload } from '../services/DataService';
import {
  AV_MEDIA_CONSTRAINTS,
  USE_IMAGE_CAPTURE_API_DURING_VIDEO_CALL,
  NO_LIMIT_FPS,
  HIGH_RES_AV_MEDIA_CONSTRAINTS,
} from './envconfig';
import {
  Artifact,
  ArtifactDetails,
  DocFetcherExtraDetails,
  Document,
  Image,
  ImageWatermarkingConfiguration,
  TasksInfo,
} from '../components/Capture/store/types';
import {
  PAGE_SEQ_CAPTURE,
  PAGE_SEQ_CONSENT,
  PAGE_SEQ_INTRO,
  PAGE_SEQ_PERMISSIONS,
  PAGE_SEQ_AVKYC,
  PAGE_SEQ_SV_SERVICE,
  DOCUMENT_TYPES,
  IMAGE_LABEL,
  COLOR_CODE,
  STATUS_CAPTURE_PENDING,
  STATUS_RECAPTURE_PENDING,
  IND_PAN_KEY,
  IND_AADHAAR_KEY,
  IND_DRIVING_LICENSE_KEY,
  IND_VOTER_KEY,
  IND_PASSPORT_KEY,
  MIME_TYPE_ZIP,
  OS_NAME,
  CAPTURE_TASKS_FETCH_AADHAAR_XML,
  CAPTURE_TASKS_DATA_CAPTURE,
  CAPTURE_TASKS_VERIFY_QA,
  CHECKS_STATUS_VERIFYING,
  CHECKS_STATUS_DONE,
  ERROR_CODES,
  SCREENSHOT_COMPRESSION_QUALITY,
  MAX_SS_HEIGHT,
  MAX_SS_WIDTH,
  PAGE_SEQ_AV_SKILL_SELECT,
  PAGE_SEQ_CAPTURE_PREREQUISITE,
  PAGE_SEQ_SV_PREREQUISITE,
  PAGE_SEQ_AV_PREREQUISITE,
  STATUS_REVIEW,
  STATUS_FAILED,
  AVKYC_ENDED_BY_ABORTING,
  AVKYC_ENDED_BY_AGENT,
  AVKYC_ENDED_BY_DROPPING,
  AVKYC_ENDED_BY_MEDIA_SERVER,
  AVKYC_ENDED_BY_SCHEDULING,
  AVKYC_ENDED_BY_USER,
  AVKYC_ENDED_BY_USER_IN_BACKGROUND,
  PAGE_SEQ_GENERIC_PAGE,
  LOCATION_SUCCESS,
  PHL_DRIVING_LICENSE_KEY,
  PHL_PASSPORT_KEY,
  PHL_SSS_KEY,
  PHL_TIN_KEY,
  PHL_VOTER_KEY,
  PHL_NBI_KEY,
  PHL_PHILSYS_KEY,
  PHL_PHILHEALTH_KEY,
  PHL_PRC_KEY,
  PHL_UMID_KEY,
  IDN_KTP_KEY,
  PHL_PHILPOST_KEY,
  IND_GST_CERTIFICATE_KEY,
  PHL_EPHIL_KEY,
  SCREEN_OVERLAY_SIZE,
} from './constants';
import { FaceAPIService } from '../services/FaceAPIService';
import i18n, { translateNumbers } from '../i18n/i18n';
import { Rg4JsWrapperService } from './raygunWrapper';
import { SupportedCategories } from '../store/ResponseBody/types';
import { ROUTE_CAPTURES } from './routes';
import { ToastTypes } from '../store/Toast/types';
import { DocumentSelection } from '../components/DocumentSelectionDialog';
import { ValidationService } from '../services/ValidationService';

type ScreenOverlayType = 'a4' | 'selfie' | 'rectangle' | 'off';

const translate = i18n.t.bind(i18n);
const supportedPageNamesInPageSequence = [
  PAGE_SEQ_CAPTURE,
  PAGE_SEQ_CONSENT,
  PAGE_SEQ_INTRO,
  PAGE_SEQ_PERMISSIONS,
  PAGE_SEQ_AVKYC,
  PAGE_SEQ_SV_SERVICE,
  PAGE_SEQ_AV_SKILL_SELECT,
  PAGE_SEQ_CAPTURE_PREREQUISITE,
  PAGE_SEQ_SV_PREREQUISITE,
  PAGE_SEQ_AV_PREREQUISITE,
  PAGE_SEQ_GENERIC_PAGE,
];

export interface FaceDetectionOutput {
  faceDetected: boolean;
  detectedFaceClass: string;
  detectedFaceMessage: string;
}
/**
 * Function to display raw Html
 * @param rawHtml
 * @returns Returns __html object
 */
export function createMarkup(rawHtml: string): { __html: string } {
  return { __html: rawHtml };
}

export type paletteKeys =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

/**
 * Function to get the palette color from theme Object
 * @param key
 */
export function getColorForPaletteType(
  key: paletteKeys,
  // eslint-disable-next-line
  themeConfig?: any,
): string {
  if (themeConfig) {
    return themeConfig.palette.type === 'dark'
      ? themeConfig.palette[key].dark
      : themeConfig.palette[key].light;
  }
  return theme().palette.type === 'dark'
    ? theme().palette[key].dark
    : theme().palette[key].light;
}

/**
 * Function to get svg color from the palette color from theme Object
 * @param key
 */
export function getSvgColor(
  key: paletteKeys,
  // eslint-disable-next-line
  variant: 'main' | 'dark' | 'light' | 'contrastText' = 'main',
  themeConfig?: any,
): string {
  if (themeConfig) {
    return themeConfig.palette[key][variant];
  }
  return theme().palette[key][variant];
}

/**
 * Function to apply custom theme to the box on intro pages
 * @param elementId
 */
//  eslint-disable-next-line
export function applyThemeToElement(elementId: string): void {
  // Will be using this later
  /* if (document.getElementById(elementId)) {
    //  eslint-disable-next-line
    //@ts-ignore
    document.getElementById(
      elementId,
    ).style.backgroundColor = getColorForPaletteType('primary');
    //  eslint-disable-next-line
    //@ts-ignore
    document.getElementById(
      elementId,
    ).style.color = theme().palette.primary.contrastText;
  } */
}
/**
 * Capitalize first character string
 * @param valToChange The string that needs to be capitalized
 */
export function capitalizeString(
  valToChange: string,
  splitOperator?: string,
  joinOperator?: string,
): string {
  return !_.isEmpty(valToChange)
    ? valToChange
        .split(splitOperator || '_')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(typeof joinOperator === 'string' ? joinOperator : ' ')
    : '';
}

/**
 * Function to get document type label options
 */
export function getDocumentTypeLabel(type: string): string {
  const val = DOCUMENT_TYPES.get(type);
  if (val) return translate(val);
  return capitalizeString(type);
}

/**
 * Function to get image capture button label
 */
export function getImageButtonLabel(type: string): string {
  const val = IMAGE_LABEL.get(type);
  if (val) return translate(val);
  const returnMessage = translate('CLICK_TO_CAPTURE');
  return returnMessage;
}

/**
 * Function to get class name from the status
 * @param status
 */
export function getColorClassName(status: string): string {
  const val = COLOR_CODE.get(status);
  if (val) return val;

  return '';
}

/**
 * Function to get individual query parameters from URL
 * @param history History Object
 * @param paramName Name of the relevant query parameter
 */
export function getQueryParamsByName(
  history: History,
  paramName: string,
): string | null {
  const params = new URLSearchParams(history.location.search);
  return params.get(paramName);
}

/**
 * Convert Base64 String to Byte array
 * @param dataURI Base64 string
 */
export function convertBase64ToByteArray(dataURI: string): Uint8Array {
  const BASE64_MARKER = ';base64,';
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  return Buffer.from(base64, 'base64');
}

/**
 * Validates page name supported in Page Sequence
 * @param pgNm Name of the page
 */
export function isALegalPageSequenceName(pgNm: string): boolean {
  return supportedPageNamesInPageSequence.indexOf(pgNm) !== -1;
}

/**
 * Checks whether the specified status value is a pending value
 * @param statusValue Status value to check
 */
export function isStatusCapturePending(statusValue: string): boolean {
  return (
    [STATUS_CAPTURE_PENDING, STATUS_RECAPTURE_PENDING].indexOf(statusValue) !==
    -1
  );
}

/**
 * Validates whether the specified document type needs to go through DIV process
 * @param docType Document type
 */
export function isADivDocument(docType: string): boolean {
  const divDocs = [
    IND_PAN_KEY,
    IND_AADHAAR_KEY,
    IND_DRIVING_LICENSE_KEY,
    IND_VOTER_KEY,
    IND_PASSPORT_KEY,
    IND_GST_CERTIFICATE_KEY,
    PHL_DRIVING_LICENSE_KEY,
    PHL_PASSPORT_KEY,
    PHL_SSS_KEY,
    PHL_TIN_KEY,
    PHL_VOTER_KEY,
    PHL_NBI_KEY,
    PHL_PHILSYS_KEY,
    PHL_PHILHEALTH_KEY,
    PHL_PRC_KEY,
    PHL_UMID_KEY,
    IDN_KTP_KEY,
    PHL_PHILPOST_KEY,
    PHL_EPHIL_KEY,
  ];
  return divDocs.indexOf(docType) !== -1;
}

export function hasZipMimeType(mimeTypesArray: string[]): boolean {
  return mimeTypesArray.indexOf(MIME_TYPE_ZIP) !== -1;
}

/**
 * returns the TAT start ref to be passed to getTatSince;
 */
export function getTatStartRef(): number {
  return performance.now();
}

/**
 * returns the TAT value since the start value;
 * @param start start Ref recived from getTatStartRef
 */
export function getTatSince(start: number): string {
  const tat = Math.round(performance.now() - start);
  return tat.toString();
}

export function setInRange(
  value: number,
  rangeMin: number,
  rangeMax: number,
): number {
  const range = Math.min(rangeMax, Math.max(rangeMin, value));
  return range;
}

export function getCanvas(
  video: HTMLVideoElement,
  minScreenshotWidth?: number,
  mirrored?: boolean,
  imageSmoothing?: boolean,
  screenOverlay?: ScreenOverlayType,
): HTMLCanvasElement | null | undefined {
  const canvas = document.createElement('canvas');

  const ctx = canvas.getContext('2d');
  const croppedImage = document.createElement('canvas');
  const croppedCtx = croppedImage.getContext('2d');
  screenOverlay = screenOverlay || 'off';

  if (canvas && ctx && croppedCtx && screenOverlay) {
    ctx.imageSmoothingEnabled = false;
    croppedCtx.imageSmoothingEnabled = false;

    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const maskWidth =
      canvas.width > canvas.height
        ? canvas.width *
          SCREEN_OVERLAY_SIZE.desktop.width[screenOverlay as ScreenOverlayType]
        : canvas.width *
          SCREEN_OVERLAY_SIZE.mobile.width[screenOverlay as ScreenOverlayType];

    const maskHeight =
      canvas.width > canvas.height
        ? canvas.height *
          SCREEN_OVERLAY_SIZE.desktop.height[screenOverlay as ScreenOverlayType]
        : canvas.height *
          SCREEN_OVERLAY_SIZE.mobile.height[screenOverlay as ScreenOverlayType];

    let top, left, target_height, target_width;

    target_height = maskHeight;
    target_width = maskWidth;

    croppedImage.width = target_width;
    croppedImage.height = target_height;

    left = video.videoWidth / 2 - target_width / 2;
    top = video.videoHeight / 2 - target_height / 2;

    croppedCtx.drawImage(
      video,
      left,
      top,
      target_width,
      target_height,
      0,
      0,
      croppedImage.width,
      croppedImage.height,
    );

    if (mirrored) {
      croppedCtx.translate(croppedImage.width, 0);
      croppedCtx.scale(-1, 1);
    } else {
      croppedCtx.translate(0, 0);
      croppedCtx.scale(1, 1);
    }

    croppedCtx.imageSmoothingEnabled = imageSmoothing || true;

    return croppedImage;
  }
  return canvas;
}

export async function loadImageWrapper(
  blob: Blob,
): Promise<HTMLCanvasElement | undefined> {
  return new Promise((resolve, reject) => {
    try {
      loadImage(
        blob,
        (canvasElement) => {
          resolve(canvasElement as HTMLCanvasElement);
        },
        { canvas: true, orientation: true },
      );
    } catch (err) {
      reject(err);
    }
  });
}

function isImageCaptureAPIAllowed(useImageCaptureAPI?: boolean): boolean {
  // @ts-ignore
  const imageCapture = window.ImageCapture;
  return (
    imageCapture &&
    (useImageCaptureAPI || USE_IMAGE_CAPTURE_API_DURING_VIDEO_CALL)
  );
}

export async function getScreenshot(data: {
  video: HTMLVideoElement;
  minScreenshotWidth?: number;
  minScreenshotHeight?: number;
  mirrored?: boolean;
  imageSmoothing?: boolean;
  useImageCaptureAPI?: boolean;
  screenshotFormat?: 'image/webp' | 'image/png' | 'image/jpeg';
  screenshotQuality?: number;
  screenOverlay: ScreenOverlayType;
}): Promise<{
  screenshot?: string | null;
  resolution: { width?: number; height?: number };
  canvasId?: string | null;
  apiUsed: string;
}> {
  let canvas: HTMLCanvasElement | undefined | null;
  let apiUsed: string = '';

  const getDefaultCanvas: () => HTMLCanvasElement | undefined | null = () =>
    getCanvas(
      data.video,
      data.minScreenshotWidth,
      data.mirrored,
      data.imageSmoothing,
      data.screenOverlay,
    );
  if (isImageCaptureAPIAllowed(data.useImageCaptureAPI)) {
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'CaptureImage',
      eventType: 'ImageCaptureAPICheck',
      eventName: 'ImageCaptureAPIAvailable',
      component: 'helpers/index.tsx',
      eventSource: 'getScreenshot',
    });
    try {
      const stream = data.video.srcObject as MediaStream;
      const track = stream.getVideoTracks()[0];
      // @ts-ignore
      const imageCapture = new ImageCapture(track);
      const { imageWidth, imageHeight, fillLightMode } =
        await imageCapture.getPhotoCapabilities();
      let ssWidth = MAX_SS_WIDTH;
      let ssHeight = MAX_SS_HEIGHT;
      if (data.minScreenshotWidth && data.minScreenshotHeight) {
        ssWidth = data.minScreenshotWidth;
        ssHeight = data.minScreenshotHeight;
      }
      const width = setInRange(ssWidth, imageWidth.min, imageWidth.max);
      const height = setInRange(ssHeight, imageHeight.min, imageHeight.max);
      const photoSettings: {
        imageWidth: number;
        imageHeight: number;
        fillLightMode?: string;
      } | null =
        width && height ? { imageWidth: width, imageHeight: height } : null;
      if (
        photoSettings &&
        fillLightMode &&
        fillLightMode.find((mode: string) => mode === 'auto')
      ) {
        photoSettings.fillLightMode = 'auto';
      }
      const image = await imageCapture.takePhoto(photoSettings);
      canvas = await loadImageWrapper(image);
      apiUsed = 'ImageCapture';
    } catch (error) {
      log(
        LogLevel.Info,
        {
          serviceCategory: 'Capture',
          service: 'CaptureImage',
          eventType: 'Exception',
          eventName: 'SCREENSHOT_FAILED',
          component: 'helpers/index.tsx',
          eventSource: 'getScreenshot',
        },
        {
          error: JSON.stringify(error),
          errorName: 'ScreenshotFailed',
          errorDescription:
            'Error in taking photo with window.ImageCapture. Resolving with getDefaultCanvas.',
        },
      );
      apiUsed = 'Canvas.DrawImage';
      canvas = getDefaultCanvas();
    }
  } else {
    const eventName =
      data.useImageCaptureAPI || USE_IMAGE_CAPTURE_API_DURING_VIDEO_CALL
        ? 'ImageCaptureAPIUnavailable'
        : 'ImageCaptureAPIDisabled';
    log(LogLevel.Info, {
      serviceCategory: 'Capture',
      service: 'CaptureImage',
      eventType: 'ImageCaptureAPICheck',
      eventName,
      component: 'helpers/index.tsx',
      eventSource: 'getScreenshot',
    });
    apiUsed = 'Canvas.DrawImage';
    canvas = getDefaultCanvas();
  }

  const screenshot =
    canvas &&
    canvas.toDataURL(
      data.screenshotFormat || 'image/jpeg',
      data.screenshotQuality || SCREENSHOT_COMPRESSION_QUALITY,
    );

  const resolution = {
    width: canvas ? canvas.width : undefined,
    height: canvas ? canvas.height : undefined,
  };
  const canvasId = canvas ? canvas.id : null;
  if (canvas && canvas.parentNode) canvas.parentNode.removeChild(canvas);
  return { screenshot, resolution, canvasId, apiUsed: apiUsed };
}

export function getUserAgent(): string {
  return navigator.userAgent;
}

export function getOperatingSystem(): string {
  const { navigator } = window;
  const { platform } = window.navigator;
  const userAgent = getUserAgent() || navigator.vendor || platform;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OS_NAME.WINDOWS_PHONE;
  }
  if (/android/i.test(userAgent)) {
    return OS_NAME.ANDROID;
  }
  // iOS detection from: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  const iOS =
    (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  if (iOS) {
    return OS_NAME.iOS;
  }
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return OS_NAME.MAC;
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return OS_NAME.WINDOWS;
  }
  if (/linux/i.test(platform)) {
    return OS_NAME.LINUX;
  }
  return '';
}

/**
 * Checks whether specified task type is allowed as a subtask of a Form
 * @param taskType Task Type to validate
 */
export function isFormSubTask(taskType: string): boolean {
  return (
    [
      CAPTURE_TASKS_FETCH_AADHAAR_XML,
      CAPTURE_TASKS_DATA_CAPTURE,
      CAPTURE_TASKS_VERIFY_QA,
    ].indexOf(taskType) !== -1
  );
}

/**
 * This function checks if key is present in the given object or not.
 * any is used for any type of object
 * @param key
 * @param obj
 */
// eslint-disable-next-line
export function findKeyInObject(key: Key, obj: any): boolean {
  return key in obj;
}

export function isIOSVersionCompatible(): boolean {
  let state = true;
  const detectedbrowser = detect(getUserAgent());
  if (detectedbrowser && detectedbrowser.name === 'safari') {
    const userAgentForIOS = getUserAgent().toLowerCase();
    // eslint-disable-next-line
    const iOSVersionAray = userAgentForIOS
      .match(/version\/[\d.]+/)![0]
      .substr(8)
      .split('.')
      .map((n) => parseInt(n, 10));
    if (iOSVersionAray[0] >= 12) {
      if (iOSVersionAray[0] === 12 && iOSVersionAray[1] <= 0) {
        state = false;
      }
    } else {
      state = false;
    }
  }
  return state;
}

export function detachVideoElement(videoElementID: string): void {
  if (getOperatingSystem() === OS_NAME.ANDROID) {
    const videoElement: HTMLVideoElement = document.getElementById(
      videoElementID,
    ) as HTMLVideoElement;
    if (videoElement) {
      log(
        LogLevel.Info,
        {
          serviceCategory: 'Capture',
          service: 'HandleVideoStream',
          eventName: 'Detach',
          eventType: 'HandleVideoElement',
          eventSource: 'detachVideoElement',
          component: 'helpers/index.tsx',
          referenceID: DataService.getAvTaskId(),
          referenceType: DataService.getAvTaskId() ? 'AV.TaskID' : '',
        },
        {
          videoElementID,
        },
      );
      videoElement.src = '';
      videoElement.srcObject = null;
    }
  }
}

export function getTaskStatus(
  state: string,
  taskInfo: TasksInfo,
  taskKey: string,
): boolean {
  let status = false;
  const task = taskInfo[taskKey];
  if (state === 'verify') {
    status = task && task.status === CHECKS_STATUS_VERIFYING;
  } else {
    status =
      task &&
      task.status === CHECKS_STATUS_DONE &&
      _.isEmpty(task.validation_error) &&
      _.isEmpty(task.error);
  }
  return status;
}

export async function changeLanguage(lng: string): Promise<void> {
  await i18n.changeLanguage(lng);
  document.body.dir = i18n.dir();
  theme().direction = i18n.dir();
}

/**
 * Detects the face from the htmlvideo
 * @param input
 */
export async function detectFaces(
  input: HTMLVideoElement | null,
  fromCameraCapture?: boolean,
): Promise<FaceDetectionOutput> {
  try {
    const faceapi = await FaceAPIService.GetFaceAPI();
    const options = FaceAPIService.mockFaceAPI
      ? undefined
      : new faceapi.TinyFaceDetectorOptions({ inputSize: 320 });
    const output = {
      faceDetected: false,
      detectedFaceClass: '',
      detectedFaceMessage: '',
    };
    if (input) {
      const detections = await faceapi.detectAllFaces(input, options);
      const numFaces = detections.length;
      if (numFaces === 0) {
        output.faceDetected = false;
        output.detectedFaceMessage = translate('FACE_NOT_VISIBLE_MESSAGE');
        output.detectedFaceClass = 'face-not-visible';
      } else if (numFaces === 1) {
        output.faceDetected = true;
        output.detectedFaceMessage = fromCameraCapture
          ? translate('FACE_VISIBLE_MESSAGE')
          : translate('SV_START_JOURNEY');
        output.detectedFaceClass = 'face-visible';
      } else {
        output.faceDetected = false;
        output.detectedFaceMessage = translate('MULTIPLE_FACE_MESSAGE');
        output.detectedFaceClass = 'face-not-visible';
      }
    }
    return output;
  } catch (error: any) {
    log(LogLevel.Error, {
      serviceCategory: 'Capture',
      service: 'FaceAPI',
      eventType: 'Failed',
      eventName: 'FaceAPIInitiate',
      component: 'helpers',
      eventSource: 'detectFaces',
      exceptionName: 'FaceAPILoadFailed',
      exceptionDescription: error.message,
    });
    return Promise.reject();
  }
}

/**
 * Converts DataURI to Uint8Array
 * @param dataURI
 * @returns
 */
export function convertDataURIToBinaryFF(dataURI: string): Uint8Array {
  const BASE64_MARKER = ';base64,';
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const raw = window.atob(dataURI.substring(base64Index));
  return Uint8Array.from(raw.split('').map((c) => c.charCodeAt(0)));
}

/**
 * Converts Date to YYYY-mm-dd format
 * @param date
 */
export function dateConversion(date: Date): string {
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function displayTimeInFormat(date: Date, duration: number): string {
  const getHours = `0${new Date(date).getHours()}`.slice(-2);
  let minutes = date.getMinutes() < 10 ? '0' : '';
  minutes += date.getMinutes();
  const upperTime = date.setTime(date.getTime() + duration * 60000);
  const getUpperHours = `0${new Date(upperTime).getHours()}`.slice(-2);
  let upperminutes = date.getMinutes() < 10 ? '0' : '';
  upperminutes += date.getMinutes();
  return `${getHours}:${minutes} - ${getUpperHours}:${upperminutes}`;
}

export function getBookingDateInFormat(date: Date): string {
  const days = [
    translate('SUNDAY'),
    translate('MONDAY'),
    translate('TUESDAY'),
    translate('WEDNESDAY'),
    translate('THURSDAY'),
    translate('FRIDAY'),
    translate('SATURDAY'),
  ];
  const monthNames = [
    translate('JANUARY'),
    translate('FEBRUARY'),
    translate('MARCH'),
    translate('APRIL'),
    translate('MAY'),
    translate('JUNE'),
    translate('JULY'),
    translate('AUGUST'),
    translate('SEPTEMBER'),
    translate('OCTOBER'),
    translate('NOVEMBER'),
    translate('DECEMBER'),
  ];
  const dayValue = days[date.getDay()];
  const dateValue = date.getDate();
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  return `${dayValue},${' '}${dateValue}${' '}${month}${' '}${year}`;
}
/*
 * Function to get document type label options
 */
export function getErrorMessageFromCode(code: string): string {
  if (code) {
    const errorMessage = ERROR_CODES.get(code);
    if (errorMessage) return translate(errorMessage);
    return capitalizeString(code);
  }
  return '';
}

export function findElement(
  elementArray: string[],
  element: string,
): string | undefined {
  return elementArray.find((e: string) => element.startsWith(e));
}

export function zeroPad(n: number): string {
  let number: string = n.toString();
  if (n <= 9) {
    number = `0${number}`;
  }
  return number;
}

export function raygunCallback(error: Error): void {
  Rg4JsWrapperService.Log({ callType: 'send', objectValue: { error } });
}

export function historyGoBack(): void {
  window.history.back();
}

/**
 * Apply className depending on status
 * @param status
 */
export function getClassBasedOnStatus(status: string): string {
  let statusClassName = '';
  if (isStatusCapturePending(status)) {
    statusClassName = 'pending';
  } else if (status === STATUS_REVIEW) {
    statusClassName = 'review';
  } else if (status === STATUS_FAILED) {
    statusClassName = 'failed';
  }
  return statusClassName;
}

export function openLink(captureUrl: string): void {
  window.open(captureUrl, '_self');
}

export function redirectTo(url: string): void {
  window.open(url, '_self');
}

export function getDefaultForCategory(
  category: SupportedCategories,
  key: 'title' | 'text',
): string {
  if (key === 'title') return '';
  const translationKey = `PROFILE_STATUS_${category.toUpperCase()}_MESSAGE`;
  return translate(translationKey);
}

export function tryAgain(): void {
  window.onbeforeunload = null;
  const url = new URL(window.location.href);
  const { searchParams } = url;
  const retry = new URLSearchParams(window.location.search).get('retry');
  searchParams.set('source', 'retry');
  if (retry) {
    let count = parseInt(retry, 10);
    if (Number.isNaN(count) || count === -1) {
      count = -1;
    } else {
      count += 1;
    }
    searchParams.set('retry', count.toString());
  } else {
    searchParams.set('retry', '1');
  }
  window.location.href = `${ROUTE_CAPTURES}?${searchParams}`;
}

export function reloadPage(): void {
  window.onbeforeunload = null;
  const url = new URL(window.location.href);
  const { searchParams } = url;
  window.location.href = `${ROUTE_CAPTURES}?${searchParams}`;
}

export function getNavigationConfirmation(
  message: string,
  callback: (allowTransition: boolean) => void,
): void {
  // eslint-disable-next-line
  const allowTransition = window.confirm(message);
  callback(allowTransition);
}

export function allowNumKeys(event: React.KeyboardEvent<HTMLDivElement>): void {
  let keyEvent = event;
  keyEvent = event || window.event;
  const charStr = event.key;
  if (
    !charStr.match(/^[0-9]+$/) &&
    keyEvent.key !== 'Backspace' &&
    keyEvent.keyCode !== 9 && // Tab
    !keyEvent.shiftKey
  )
    keyEvent.preventDefault();
}

export function confirmExit(): void | string {
  const askLeavePage = translate('ASK_LEAVE_PAGE');
  return askLeavePage;
}

export function displayToast(message: string): void {
  const payload = {
    message,
    type: ToastTypes.error,
    autoHideDuration: 5000,
  };
  DataService.displayToast(payload);
}

export function getZeroBeforeSeconds(s: number): ReactText {
  return translateNumbers(s < 10 ? `0${s}` : s);
}

export function secondsToTime(secs: number): {
  h: number;
  m: number;
  s: number;
} {
  const hours = Math.floor(secs / (60 * 60));

  const divisorForMinutes: number = secs % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  let seconds = Math.ceil(divisorForSeconds);
  seconds = seconds === 0 ? 0 : seconds;

  const obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
}

export function getCaptureRootElement(): HTMLElement {
  return document.getElementById('scrollable-content-holder') as HTMLElement;
}

export function getDocumentName(
  artifact: Artifact,
  template: Document,
): string {
  let docName = '';
  if (artifact.document_type_key) {
    docName = getDocumentTypeLabel(artifact.document_type_key);
  } else if (artifact.category) {
    docName = capitalizeString(artifact.category);
  } else if (template.document_type_key) {
    docName = getDocumentTypeLabel(template.document_type_key);
  } else if (template.category_type) {
    docName = capitalizeString(template.category_type);
  } else if (template.label) {
    docName = template.label;
  }
  return docName;
}

export function /**
 * Redirects to Digilocker journey
 * @param template
 * @param extraFieldsData
 */
redirectToDigilocker(
  template: Document | Image,
  extraFieldsData?: DocFetcherExtraDetails,
): void {
  let source = '';
  if (template.capture_mechanisms && template.capture_mechanisms.digilocker) {
    source = 'digilocker';
  } else if (
    template.capture_mechanisms &&
    template.capture_mechanisms.leegality
  ) {
    source = 'leegality';
  }
  const isDigilockerExtraFieldsPresent =
    template &&
    template.capture_mechanisms &&
    template.capture_mechanisms.digilocker &&
    'details' in template.capture_mechanisms.digilocker;
  const payload: FetchDocumentPayload = {
    document_type_key: template.artifacts[0].split('.')[1],
    artifact_key: template.artifacts[0],
    details: isDigilockerExtraFieldsPresent ? extraFieldsData : {},
    source: source,
  };
  log(
    LogLevel.Info,
    {
      serviceCategory: 'Capture',
      service: 'DocumentFetcherService',
      eventType: 'DigilockerOptionClicked',
      eventName: payload.artifact_key,
      component: 'DocumentFetcher',
      eventSource: 'redirectToDigilocker',
    },
    {
      payload,
    },
  );

  DataService.sessionFetchDocument(payload);
}

export function getBrowserByOS(): string {
  if (getOperatingSystem() !== OS_NAME.iOS) {
    return translate('GOOGLE_CHROME');
  }
  return translate('SAFARI');
}

export function openChrome(isWebview: boolean): void {
  log(LogLevel.Info, {
    serviceCategory: 'Capture',
    service: 'CompatibilityCheck',
    eventType: 'Clicked',
    eventName: 'Open Chrome',
    component: 'BrowserNotSupported',
    eventSource: 'openChrome',
  });
  const url = isWebview
    ? 'https://play.google.com/store/apps/details?id=com.google.android.webview'
    : 'https://play.google.com/store/apps/details?id=com.android.chrome';
  window.open(url, '_self');
}

/**
 * Returns the video Element
 */
export function getVideoElement(): HTMLVideoElement {
  return document.getElementById('pgCapture') as HTMLVideoElement;
}

/**
 * On next and previous click adjust the scroll
 */
export function resetScrollPosition(scrollTop: boolean): void {
  const root = getCaptureRootElement();
  if (root) {
    if (scrollTop) {
      root.scrollTo(0, 0);
    } else {
      root.scrollTo(0, root.scrollHeight);
    }
  }
}

/**
 * Cleanup all the streams
 */
export function cleanupStream(): void {
  const videoElement = getVideoElement();
  if (videoElement) {
    const stream = getVideoElement().srcObject as MediaStream;
    videoElement.src = '';
    videoElement.srcObject = null;
    stream &&
      stream.getTracks().forEach((track) => {
        track.stop();
        // eslint-disable-next-line
        track.enabled = false;
      });
  }
}

export function getEndMechanismFromCode(endMechanism: number): string {
  let endedAs = '';
  if (endMechanism === AVKYC_ENDED_BY_USER) {
    endedAs = 'user_ended_call';
  } else if (endMechanism === AVKYC_ENDED_BY_MEDIA_SERVER) {
    endedAs = 'mediaserver_disconnect';
  } else if (endMechanism === AVKYC_ENDED_BY_SCHEDULING) {
    endedAs = 'user_scheduled';
  } else if (endMechanism === AVKYC_ENDED_BY_ABORTING) {
    endedAs = 'aborted';
  } else if (endMechanism === AVKYC_ENDED_BY_USER_IN_BACKGROUND) {
    endedAs = 'user_in_background';
  } else if (endMechanism === AVKYC_ENDED_BY_AGENT) {
    endedAs = 'agent_disconnected';
  } else if (endMechanism === AVKYC_ENDED_BY_DROPPING) {
    endedAs = 'dropped';
  } else {
    endedAs = 'call_end_unhandled';
  }
  return endedAs;
}

export function getConstraint(
  withAudio = true,
  fromCameraCapture = false,
): MediaStreamConstraints {
  let constraints =
    AV_MEDIA_CONSTRAINTS && JSON.parse(atob(AV_MEDIA_CONSTRAINTS));
  if (fromCameraCapture && isIOSOrSafari()) {
    constraints =
      HIGH_RES_AV_MEDIA_CONSTRAINTS &&
      JSON.parse(atob(HIGH_RES_AV_MEDIA_CONSTRAINTS));
  }
  if (NO_LIMIT_FPS) {
    delete constraints.video.frameRate;
  }
  if (!withAudio) {
    constraints.audio = false;
  }
  constraints.video.facingMode = 'user';
  return constraints;
}

export function getDocType(
  template: Image,
  dlgSelection?: DocumentSelection,
): string {
  let docType = '';
  if (template && template.document_type_key) {
    docType = template.document_type_key;
  }
  if (docType === '') {
    docType = (dlgSelection && dlgSelection.displayKey) || '';
  }
  return docType;
}

/**
 * Handles Image capture Button label
 * @param template Image template
 */
export function getImageLabel(template: Image): string {
  if (template && template.label) return template.label;
  if (template && template.side) return getImageButtonLabel(template.side);
  return '';
}

export function getDownloadURL(artifact: Artifact): string {
  let downloadURL = artifact.value;
  if (
    artifact.storage_provider &&
    artifact.download_urls &&
    !_.isEmpty(artifact.download_urls) &&
    artifact.content_type &&
    artifact.download_urls[artifact.content_type]
  ) {
    downloadURL = artifact.download_urls[artifact.content_type].url;
  }
  return DataService.buildUrl(downloadURL, 'GET');
}

export function getButtonBackgroundColor(
  isSuccessStatus: boolean,
  isVerifyStatus: boolean,
): string {
  let bgColor = theme().palette.primary.main;
  if (isSuccessStatus) {
    bgColor = theme().palette.success.main;
  } else if (isVerifyStatus) {
    bgColor = 'rgba(0, 0, 0, 0.26)';
  }
  return bgColor;
}

/**
 * Function to check received config is valid for a specific page
 * @param page
 * @param resp
 * @returns boolean
 */
function isValidConfig(page: string, resp: any): boolean {
  if (
    page === PAGE_SEQ_CAPTURE ||
    page === PAGE_SEQ_GENERIC_PAGE ||
    page === PAGE_SEQ_AV_PREREQUISITE ||
    page === PAGE_SEQ_SV_PREREQUISITE ||
    page === PAGE_SEQ_CAPTURE_PREREQUISITE ||
    page === PAGE_SEQ_AV_SKILL_SELECT ||
    page === PAGE_SEQ_AVKYC ||
    page === PAGE_SEQ_SV_SERVICE
  ) {
    return _.isEmpty(resp.data.config);
  }
  return false;
}

/**
 * Function to decide whether to skip the page from journey
 * @param resp
 * @returns boolean
 */
export function shouldSkipPage(resp: any): boolean {
  return isValidConfig(resp.data.page, resp);
}

export function getCurrentDate(): string {
  const fullDate = new Date();
  const date =
    ('0' + fullDate.getDate()).slice(-2) +
    ':' +
    ('0' + (fullDate.getMonth() + 1)).slice(-2) +
    ':' +
    fullDate.getFullYear();
  return date;
}

export function getCurrentTime(): string {
  const fullDate = new Date();
  const time =
    ('0' + fullDate.getHours()).slice(-2) +
    ':' +
    ('0' + fullDate.getMinutes()).slice(-2) +
    ':' +
    ('0' + fullDate.getSeconds()).slice(-2);
  return time;
}

export async function watermarkImage(
  base64URL: string,
  imageWatermarkingConfiguration: ImageWatermarkingConfiguration,
  artifactDetails: ArtifactDetails,
): Promise<string> {
  const captureWatermarkData = imageWatermarkingConfiguration.keys.filter(
    (data) => data.source === 'capture',
  );
  const artifactWatermarkData = imageWatermarkingConfiguration.keys.filter(
    (data) => data.source === 'artifact',
  );
  const totalLines =
    !_.isEmpty(captureWatermarkData) && !_.isEmpty(artifactWatermarkData)
      ? 2
      : 1;
  const img: HTMLImageElement = document.createElement('img');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  window.devicePixelRatio = 2;
  const scale = window.devicePixelRatio;
  img.src = base64URL;
  return new Promise((resolve) => {
    img.onload = async () => {
      ctx.scale(scale, scale);
      canvas.style.width = img.width + 'px';
      canvas.style.height = img.height + 'px';
      canvas.width = img.width;
      canvas.height = img.height + img.height * totalLines * 0.05;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      ctx.font = `normal ${(img.width * 1.7) / 100}pt Roboto`;
      ctx.fillStyle = 'rgba(0, 0, 0, 0.75)';
      ctx.fillRect(0, img.height, img.width, img.height * (totalLines * 0.05));
      ctx.textAlign = 'start';
      ctx.fillStyle = 'rgba(255, 255, 255, 1.0)';
      if (captureWatermarkData.length > 0) {
        let captureWatermarkString: string = '';
        for (let i = 0; i < captureWatermarkData.length; i++) {
          if (captureWatermarkData[i].value === 'date') {
            captureWatermarkString += `| ${
              captureWatermarkData[i].label
            }: ${getCurrentDate()} `;
          } else if (captureWatermarkData[i].value === 'time') {
            captureWatermarkString += `| ${
              captureWatermarkData[i].label
            }: ${getCurrentTime()} `;
          } else if (captureWatermarkData[i].value === 'geolocation') {
            const location = await ValidationService.getLastSavedUserLocation();
            if (location.errorCode === LOCATION_SUCCESS) {
              captureWatermarkString += `| ${captureWatermarkData[i].label}: ${location.data.latitude}, ${location.data.longitude}`;
            }
          } else {
            captureWatermarkString += '';
          }
        }
        ctx.fillText(
          captureWatermarkString.trim() + ' |',
          img.width * 0.02,
          totalLines === 1 ? canvas.height * 0.98 : canvas.height * 0.94,
          img.width * 0.96,
        );
      }
      if (artifactWatermarkData.length > 0) {
        let artifactWatermarkString = '';
        artifactWatermarkData.forEach((artifactData) => {
          const artifactKey = artifactData.value;
          artifactWatermarkString += ` | ${artifactData.label}: ${
            artifactDetails[artifactKey]
              ? artifactDetails[artifactKey].value
              : 'NA'
          }`;
        });
        ctx.fillText(
          artifactWatermarkString.trim() + ' |',
          img.width * 0.02,
          canvas.height * 0.98,
          img.width * 0.96,
        );
      }
      let canvasURL: string = canvas.toDataURL('image/jpeg');
      resolve(canvasURL);
    };
  });
}

// As videoOff and videoOn causing reconnect and out of packets issues in DeskType Browser.
// Right Now,it is noticed in Mac with Chrome v124.
export function disableVideoDuringSS() {
  try {
    if (
      [OS_NAME.MAC, OS_NAME.LINUX, OS_NAME.WINDOWS].includes(
        getOperatingSystem() as OS_NAME,
      )
    ) {
      return false;
    }
    return true;
  } catch (e) {
    return true;
  }
}

export function isIOSOrSafari(): boolean {
  const browser = detect(getUserAgent());
  return (
    browser?.os === 'iOS' ||
    browser?.name === 'safari' ||
    browser?.name === 'ios-webview'
  );
}

export const getPageName = (state: any) => {
  let pageName = '';
  if (state?.pagePath) {
    pageName = state.pagePath;
  }
  if (state?.pageKey) {
    pageName += ' -> ' + state.pageKey;
  }
  if (state?.capture_config_key) {
    pageName += ' -> ' + state.capture_config_key;
  }
  return pageName || 'UNKNOWN_PAGE';
};
