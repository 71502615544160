import React, { ReactNode } from 'react';
import {
  LOCATION_SUCCESS,
  GEOLOCATION_MAXIMUM_AGE,
  LOCATION_TIMEOUT,
  PROXY_CHECK_SUCCESS,
  OS_NAME,
  LOCATION_UNAVAILABLE,
  LOCATION_PERMISSION_DENIED,
  GEOLOCATION_TIMEOUT_WITH_FALLBACK,
  GEOLOCATION_TIMEOUT_WITHOUT_FALLBACK,
} from '../helpers/constants';
import ValidationFailureComponent from '../components/ValidationFailureComponent';
import { log, LogLevel } from '../helpers/loggerWrapper';
import { capitalizeString, getOperatingSystem } from '../helpers';
import _ from 'lodash';
import { DataService } from './DataService';

interface ValidationRegistry {
  [ident: string]: {
    // eslint-disable-next-line
    fetchDataFunc: () => Promise<any>;
    errorComponent: ReactNode;
  };
}

export interface LocationSettings {
  latitude: number;
  longitude: number;
  altitude: number | null;
  accuracy: number;
  altitudeAccuracy: number | null;
  heading: number | null;
  speed: number | null;
  timestamp: string;
  source?: string;
}

interface ValidationDetails {
  errorCode: number;
  data: LocationSettings | Record<string, unknown>;
  errorMessage: string;
}

export interface ValidationRequest {
  attr: string;
  data: LocationSettings | Record<string, unknown>;
  source?: string;
}

export interface ValidationResponse {
  payload: ValidationRequest[];
  page: string;
  page_key?: string | undefined;
}

export interface ValidationResult {
  error: boolean;
  code: number;
  payload: ValidationResponse;
}

export class ValidationService {
  private static validationsConfig: ValidationRegistry = {
    location: {
      fetchDataFunc: ValidationService.getCurrentUserLocation,
      errorComponent: <ValidationFailureComponent />,
    },
    proxy_check: {
      fetchDataFunc: ValidationService.getProxyDataFunc,
      errorComponent: <ValidationFailureComponent />,
    },
  };

  private static unknownLocation: LocationSettings = {
    latitude: -1,
    longitude: -1,
    altitude: null,
    accuracy: -1,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
    timestamp: new Date(0).toISOString(),
  };

  private static captureLocation: LocationSettings =
    ValidationService.unknownLocation;

  private static mockValidation = false;

  /**
   * Mock for unit test cases
   */
  static SetServiceBehaviour(): void {
    ValidationService.mockValidation = true;
  }

  static async GetData(
    page: string,
    validations: string[],
    pageKey: string | undefined = undefined,
  ): Promise<ValidationResult> {
    const payload: ValidationRequest[] = [];
    let errorFlag = false;
    let locErrorCode = LOCATION_SUCCESS;
    for (let index = 0; index < validations.length; index += 1) {
      log(
        LogLevel.Info,
        {
          serviceCategory: 'Capture',
          service: 'PageValidations',
          eventType: 'DataCollection',
          eventName: capitalizeString(validations[index]),
          component: 'ValidationService',
          eventSource: 'GetData',
          logVersion: 'v2',
          publishToDLK: true,
        },
        {
          hard_stop: true,
        },
      );
      let element = validations[index];
      if (element.includes('ip_address_')) {
        element = 'proxy_check';
      } else if (element.includes('gps_')) {
        element = 'location';
      }
      if (ValidationService.validationsConfig[element]) {
        try {
          const data = await this.validationsConfig[element].fetchDataFunc();
          locErrorCode =
            data.errorCode === PROXY_CHECK_SUCCESS
              ? locErrorCode
              : data.errorCode;
          payload.push({
            attr: element,
            data: data.data,
          });
        } catch (error) {
          errorFlag = true;
        }
      } else {
        Promise.reject(new Error('Unsupported Validation'));
      }
    }
    const finalPayload: ValidationResponse = {
      page,
      page_key: pageKey,
      payload,
    };
    return { error: errorFlag, code: locErrorCode, payload: finalPayload };
  }

  /**
   * Helper method to return ErrorComponent depending on validation error
   * @param ident
   */
  static GetErrorComponent(ident: string): ReactNode {
    return ValidationService.validationsConfig[ident].errorComponent;
  }

  /**
   * Helper method to return ErrorComponent depending on validation error
   * @param ident
   */
  static GetValidationFunction(ident: string): () => void {
    return ValidationService.validationsConfig[ident].fetchDataFunc;
  }

  /**
   * Returns users current location in lat long
   */
  static getCurrentUserLocation(): Promise<ValidationDetails> {
    if (ValidationService.mockValidation) {
      return Promise.resolve({
        errorCode: 0,
        errorMessage: '',
        data: ValidationService.unknownLocation,
      });
    }
    let data: LocationSettings;
    return new Promise((resolve) => {
      let timer: NodeJS.Timeout;
      if (getOperatingSystem() === OS_NAME.iOS) {
        timer = setTimeout(() => {
          resolve({
            errorCode: LOCATION_TIMEOUT,
            data: ValidationService.unknownLocation,
            errorMessage: 'Failed to fetch location',
          });
          clearTimeout(timer);
        }, 30000);
      }
      navigator.geolocation.getCurrentPosition(
        (res) => {
          clearTimeout(timer);
          data = {
            latitude: res.coords.latitude,
            longitude: res.coords.longitude,
            altitude: res.coords.altitude,
            accuracy: res.coords.accuracy,
            altitudeAccuracy: res.coords.altitudeAccuracy,
            heading: res.coords.heading,
            speed: res.coords.speed,
            timestamp: new Date(res.timestamp).toISOString(),
            source: 'GPS',
          };
          log(
            LogLevel.Info,
            {
              serviceCategory: 'Capture',
              service: 'CaptureLocation',
              eventType: 'Success',
              eventName: 'navigatorGeolocationNative',
              component: 'ValidationService',
              eventSource: 'getCurrentUserLocation',
            },
            {
              coordinates: {
                lat: res.coords.latitude,
                lng: res.coords.longitude,
                accuracy: res.coords.accuracy,
              },
            },
          );

          // Store navigator results in class variable
          ValidationService.captureLocation = data;

          resolve({
            errorCode: LOCATION_SUCCESS,
            data,
            errorMessage: 'Location captured successfully.',
          });
        },
        async (error) => {
          clearTimeout(timer);
          let eventName =
            error.code === LOCATION_TIMEOUT
              ? 'Timeout'
              : error.code === LOCATION_UNAVAILABLE
              ? 'PositionUnavailable'
              : error.code === LOCATION_PERMISSION_DENIED
              ? 'PermissionDenied'
              : 'Failed';
          log(
            LogLevel.Error,
            {
              serviceCategory: 'Capture',
              service: 'CaptureLocation',
              eventType: 'Exception',
              eventName: eventName,
              component: 'ValidationService',
              eventSource: 'getCurrentUserLocation',
              exceptionName: 'Error while Acquiring location',
              exceptionDescription: error.message,
            },
            {
              error_code: error.code,
              error_message: error.message,
            },
          );
          if (
            error.code === LOCATION_TIMEOUT ||
            error.code === LOCATION_UNAVAILABLE
          ) {
            if (DataService.isFallbackToLocationFromIPAddressEnabled()) {
              const locationFromIP = await DataService.fetchGPSFromIPAddress();
              if (
                locationFromIP.latitude !== -1 &&
                locationFromIP.longitude !== -1
              ) {
                data = {
                  latitude: locationFromIP.latitude as number,
                  longitude: locationFromIP.longitude as number,
                  accuracy: -1,
                  altitude: -1,
                  altitudeAccuracy: -1,
                  heading: -1,
                  speed: -1,
                  timestamp: Date.now().toString(),
                  source: 'IP Address',
                };
                // Store navigator results in class variable
                ValidationService.captureLocation = data;
                resolve({
                  errorCode: LOCATION_SUCCESS,
                  data,
                  errorMessage:
                    'Location captured successfully from IP Address',
                });
              } else {
                log(LogLevel.Error, {
                  serviceCategory: 'Capture',
                  service: 'FetchLocationFromIPAddress',
                  eventType: 'Exception',
                  eventName: 'Failed',
                  component: 'PermissionsService',
                  eventSource: 'getLocationPermission',
                });
                resolve({
                  errorCode: error.code,
                  data: ValidationService.unknownLocation,
                  errorMessage: 'Unable to fetch location from IP Address',
                });
              }
            } else {
              log(LogLevel.Warning, {
                serviceCategory: 'Capture',
                service: 'FetchLocationFromIPAddress',
                eventType: 'Disabled',
                eventName: 'Disabled',
                component: 'ValidationService',
                eventSource: 'getCurrentUserLocation',
              });
            }
          }
          resolve({
            errorCode: error.code,
            data: ValidationService.unknownLocation,
            errorMessage: error.message,
          });
        },
        {
          timeout: DataService.isFallbackToLocationFromIPAddressEnabled()
            ? GEOLOCATION_TIMEOUT_WITH_FALLBACK
            : GEOLOCATION_TIMEOUT_WITHOUT_FALLBACK,
          maximumAge: GEOLOCATION_MAXIMUM_AGE,
          enableHighAccuracy: true,
        },
      );
    });
  }

  static setLastSavedUserLocation(locationDetails: LocationSettings): void {
    ValidationService.captureLocation = locationDetails;
  }

  static getLastSavedUserLocation(): Promise<ValidationDetails> {
    if (
      _.isEqual(
        ValidationService.captureLocation,
        ValidationService.unknownLocation,
      )
    ) {
      return ValidationService.getCurrentUserLocation();
    }
    return Promise.resolve({
      data: ValidationService.captureLocation,
      errorCode: LOCATION_SUCCESS,
      errorMessage: 'Location captured successfully.',
    });
  }

  static getLiveLocation(taskID: string): LocationSettings {
    const locationResponse = ValidationService.captureLocation;
    locationResponse.latitude = Number(locationResponse.latitude.toFixed(5));
    locationResponse.longitude = Number(locationResponse.longitude.toFixed(5));
    log(
      LogLevel.Info,
      {
        serviceCategory: 'Capture',
        service: 'CaptureLocation',
        eventType: 'Captured',
        eventName: locationResponse.accuracy.toString(),
        component: 'ValidationService',
        eventSource: 'getLiveLocation',
        referenceID: taskID,
        referenceType: 'AV.TaskID',
      },
      {
        modeType: 'HighAccuracy',
        request_uid: taskID,
      },
    );

    // Logs for logging and comparing with the google geoloc and ipdata loc
    log(
      LogLevel.Info,
      {
        serviceCategory: 'Capture',
        service: 'CaptureLocation',
        eventType: 'fetchLocationSuccess',
        eventName: 'navigatorLocation',
        component: 'ValidationService',
        eventSource: 'getLiveLocation',
        referenceID: taskID,
        referenceType: 'AV.TaskID',
      },
      {
        locationDetails: {
          coordinates: {
            lat: locationResponse.latitude,
            lng: locationResponse.longitude,
            accuracy: locationResponse.accuracy,
          },
          threat: null,
        },
        request_uid: taskID,
      },
    );

    return locationResponse;
  }

  /**
   * Returns users proxy data
   */
  static getProxyDataFunc(): Promise<ValidationDetails> {
    return new Promise((resolve) => {
      resolve({
        data: {},
        errorCode: PROXY_CHECK_SUCCESS,
        errorMessage: '',
      });
    });
  }
}
