import {
  CAPTURE_CONF,
  MULTIPLE_SESSION,
  SAVE_PAGE_CONFIG,
  PAGE_SEQ,
} from '../../../helpers/actionTypes';
import { SkillDetails } from '../../AssistedVideoKYC/AVSkillSelect/store/types';
import { AVKycPageData } from '../../AssistedVideoKYC/store/types';
import { CaptureConfigDetails } from '../../Capture/store/types';
import { PermissionDetails } from '../../PermissionsPage/store/types';
import { PrerequisitePageConfig } from '../../PrerequisitePage/store/types';
import { SVServicePageData } from '../../SelfVideoService/store/types';
/**
 * Type declaration for Capture Component
 */

export interface PageDetails {
  title: string;
  text: string;
}

export interface SchedulingInfo {
  enabled: boolean;
  slots_available: boolean;
  start_date: string;
  end_date: string;
  booking_details: {
    reference_id: string;
  };
}

export interface PageConfig {
  page: string;
  validations: string[];
  hasConfig: boolean;
  config?:
    | PageDetails
    | CaptureConfigDetails
    | PermissionDetails
    | PrerequisitePageConfig
    | SkillDetails[]
    | AVKycPageData
    | SVServicePageData;
  page_key?: string;
  page_type?: string;
}

export interface CaptureSequence {
  pageSequence: PageConfig[];
  settings: {
    [ident: string]: boolean | string[];
  };
}

export interface PageSequence {
  pageSequence: PageConfig[];
}

interface CaptureAction {
  type: typeof CAPTURE_CONF;
  payload: CaptureSequence;
}

interface PageSequenceAction {
  type: typeof PAGE_SEQ;
  payload: PageSequence;
}

export interface OverrideSession {
  value: string;
}
export const OVERRIDE_SESSION_UNKNOWN = 'unk';
export const OVERRIDE_SESSION_CONFIRM = 'ask';
export const OVERRIDE_SESSION_ACKNOWLEDGED = 'ack';

interface OverrideSessionAction {
  type: typeof MULTIPLE_SESSION;
  payload: OverrideSession;
}

export interface PageData {
  [ident: number]: {
    title: string;
    text: string;
    name: string;
  };
}

interface SavePageConfigAction {
  type: typeof SAVE_PAGE_CONFIG;
  payload: PageData;
}

export interface NetworkCheckConfig {
  timeout: number;
  enabled: boolean;
  checks: Record<string, boolean>;
}

export type CaptureActions = CaptureAction | PageSequenceAction;
export type OverrideSessionActions = OverrideSessionAction;
export type SavePageConfigActions = SavePageConfigAction;
